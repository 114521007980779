
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import NotificationModel from '@/models/NotificationModel'
import Notification from '@/dto/Notification'

export default defineComponent({
  name: 'Notification',
  setup() {
    const Toast = useToast()
    return {
      Auth,
      Toast,
    }
  },

  data() {
    return {
      loadedNotificationAmount: 5,
      totalNotification: 0,
      unreadNotification: [] as Notification[],
    }
  },

  mounted() {
    this.loadNotification()
  },

  computed: {
    // getters: notification loading, data & pagination(for loadmore function)
    getNotificationLoading(): boolean {
      return this.$store.getters['notificationStore/getNotificationDataLoading']
    },
    getNotificationData(): Array<Notification> {
      return this.$store.getters['notificationStore/getNotificationData']
    },
    getNotificationPagination() {
      return this.$store.getters['notificationStore/getNotificationPagination']
    },
    getUnReadNotificationData(): Array<Notification> {
      return this.$store.getters['notificationStore/getUnReadNotificationData']
    },
  },

  methods: {
    async loadNotification() {
      await this.$store.dispatch(
        'notificationStore/fetchUnreadNotificationData'
      )
      await this.$store
        .dispatch(
          'notificationStore/fetchNotificationData',
          this.loadedNotificationAmount
        )
        .then(() => {
          // on finished dispatch set total notification
          this.totalNotification = this.getNotificationPagination.total
        })
      // this.filterNotification()
    },
    async readSingleNotification(data: any) {
      if (data.is_read == false) {
        this.$store.commit(
          'notificationStore/READ_SINGLE_NOTIFICATION',
          data.id
        )
        await NotificationModel.readSingle(data.id)
        this.loadNotification()
      }
    },
    // function: load more notification
    async loadMoreNotification() {
      // increment loaded notification amount with 10
      this.loadedNotificationAmount += 5

      // check if previous loaded class data more than notification total data
      // set it to total data
      if (this.loadedNotificationAmount > this.totalNotification) {
        this.loadedNotificationAmount = this.totalNotification
      }

      // execute fetch notification data with settled amount loaded notification data
      await this.$store.dispatch(
        'notificationStore/fetchNotificationData',
        this.loadedNotificationAmount
      )
      // this.readNotification = this.
      // this.filterNotification()
    },

    // filterNotification() {
    //   this.unReadNotification = []
    //   this.readNotification = []
    //   if (this.getNotificationData.length) {
    //     const dateNow = moment().format('YYYY-MM-DD 00:00:00')
    //     this.getNotificationData.forEach((el: any) => {
    //       const createdAt = moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')
    //       if (createdAt > dateNow) {
    //         this.unReadNotification.push(el)
    //       } else {
    //         this.readNotification.push(el)
    //       }
    //     })
    //   }
    // },

    dateLong(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
    },

    dateNotif(date: string) {
      return moment(date)
        .locale('id')
        .format('D MMM')
    },

    timeSince(date: any) {
      const dateSince: any = new Date(date)
      const dateNow: any = new Date()
      const seconds = Math.floor((dateNow - dateSince) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' tahun lalu'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' bulan lalu'
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' hari lalu'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' jam lalu'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' menit lalu'
      }
      return Math.floor(seconds) + ' detik lalu'
    },
  },
})
