<template>
  <div class="h-auto min-h-[90vh]">
    <div
      class="mt-6 md:mt-10 mb-5 max-w-6xl px-3 md:px-6 mx-auto flex items-center"
    >
      <p class="block font-desain text-[#212121] font-semibold text-2xl">
        Notifikasi
      </p>
    </div>
    <!-- <div class="max-w-6xl mx-auto px-8 w-full flex flex-row items-center overflow-auto scroll-hide">
      <div
        @click="openTab('new')"
        class="font-solusi text-base font-semibold px-6 py-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          tab === 'new'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#757575] border-transparent'
        "
      >
        Terbaru
      </div>
      <div
        @click="openTab('old')"
        class="ml-4 font-solusi text-base font-semibold px-4 py-2 border-b-2 flex-shrink-0 duration-300 cursor-pointer"
        :class="
          tab === 'old'
            ? 'border-[#009DFF] text-[#009DFF]'
            : 'text-[#757575] border-transparent'
        "
      >
        Sebelumnya
      </div>
    </div> -->
    <div
      v-if="
        getNotificationData.length > 0 && getUnReadNotificationData.length > 0
      "
      class="block m-auto max-w-6xl md:px-8"
    >
      <div
        class="bg-[#F6FBFF] font-desain text-[#009DFF] font-semibold text-lg p-3 border-l-4 border-[#009DFF]"
      >
        Belum Terbaca
      </div>
      <div class="border-b-4 border-[#F5F6F8]"></div>
    </div>
    <div
      v-if="getUnReadNotificationData.length > 0"
      class="max-w-6xl mx-auto md:px-8 w-full flex flex-col md:space-y-3"
    >
      <div
        v-for="(notif, i) in getUnReadNotificationData"
        :key="i"
        class="p-3 hover:bg-gray-100 duration-300 flex flex-col cursor-pointer"
        :class="notif?.is_read ? 'bg-[#FFFFFF]' : 'bg-[#FFFAF1]'"
        @click="readSingleNotification(notif)"
      >
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9 md:col-span-10 flex flex-col">
            <p class="font-solusi text-base font-semibold text-[#333333] mb-2">
              {{ notif?.content }}
            </p>
            <span class="font-solusi text-xs text-[#757575] font-medium">{{
              dateLong(notif.created_at)
            }}</span>
          </div>
          <div class="col-span-3 md:col-span-2">
            <div class="flex items-center justify-end">
              <svg
                v-if="!notif?.is_read"
                class="w-2 h-2"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="4" cy="4" r="4" fill="#009DFF" />
              </svg>
              <span class="font-solusi text-xs text-[#757575] font-medium ml-1">
                {{ timeSince(notif?.created_at) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        getNotificationData.length > 0 && getUnReadNotificationData.length > 0
      "
      class="block m-auto max-w-6xl md:px-8 mt-6"
    >
      <div
        class="bg-[#F5F7FD] font-desain text-[#757575] font-semibold text-lg p-3 border-l-4 border-[#757575]"
      >
        Sudah Terbaca
      </div>
      <div class="border-b-4 border-[#F5F6F8]"></div>
    </div>
    <div
      v-if="getNotificationData.length > 0"
      class="max-w-6xl mx-auto md:px-8 w-full flex flex-col md:space-y-3"
    >
      <div
        v-for="(notif, i) in getNotificationData"
        :key="i"
        class="p-3 hover:bg-gray-100 duration-300 flex flex-col"
        :class="[
          notif?.is_read ? 'bg-[#FFFFFF]' : 'bg-[#FFFAF1] cursor-pointer',
        ]"
        @click="readSingleNotification(notif)"
      >
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-9 md:col-span-10 flex flex-col">
            <p class="font-solusi text-base font-semibold text-[#333333] mb-2">
              {{ notif?.content }}
            </p>
            <span class="font-solusi text-xs text-[#757575] font-medium">{{
              dateLong(notif?.created_at)
            }}</span>
          </div>
          <div class="col-span-3 md:col-span-2">
            <div class="flex items-center justify-end">
              <svg
                v-if="!notif?.is_read"
                class="w-2 h-2"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="4" cy="4" r="4" fill="#009DFF" />
              </svg>
              <span class="font-solusi text-xs text-[#757575] font-medium ml-1">
                {{ timeSince(notif?.created_at) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loadedNotificationAmount < totalNotification"
      class="max-w-6xl mx-auto md:px-8 w-full flex flex-col justify-center items-center mt-6"
    >
      <svg
        v-if="getNotificationLoading && totalNotification > 0"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="spinner"
        class="w-6 h-6 animate-spin"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#00446F"
          d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
        ></path>
      </svg>
      <button
        v-else
        class="font-solusi font-semibold text-sm bg-[#00446F] text-[#FFFFFF] px-8 py-2 rounded-full"
        @click="loadMoreNotification"
      >
        Muat Lagi
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import NotificationModel from '@/models/NotificationModel'
import Notification from '@/dto/Notification'

export default defineComponent({
  name: 'Notification',
  setup() {
    const Toast = useToast()
    return {
      Auth,
      Toast,
    }
  },

  data() {
    return {
      loadedNotificationAmount: 5,
      totalNotification: 0,
      unreadNotification: [] as Notification[],
    }
  },

  mounted() {
    this.loadNotification()
  },

  computed: {
    // getters: notification loading, data & pagination(for loadmore function)
    getNotificationLoading(): boolean {
      return this.$store.getters['notificationStore/getNotificationDataLoading']
    },
    getNotificationData(): Array<Notification> {
      return this.$store.getters['notificationStore/getNotificationData']
    },
    getNotificationPagination() {
      return this.$store.getters['notificationStore/getNotificationPagination']
    },
    getUnReadNotificationData(): Array<Notification> {
      return this.$store.getters['notificationStore/getUnReadNotificationData']
    },
  },

  methods: {
    async loadNotification() {
      await this.$store.dispatch(
        'notificationStore/fetchUnreadNotificationData'
      )
      await this.$store
        .dispatch(
          'notificationStore/fetchNotificationData',
          this.loadedNotificationAmount
        )
        .then(() => {
          // on finished dispatch set total notification
          this.totalNotification = this.getNotificationPagination.total
        })
      // this.filterNotification()
    },
    async readSingleNotification(data: any) {
      if (data.is_read == false) {
        this.$store.commit(
          'notificationStore/READ_SINGLE_NOTIFICATION',
          data.id
        )
        await NotificationModel.readSingle(data.id)
        this.loadNotification()
      }
    },
    // function: load more notification
    async loadMoreNotification() {
      // increment loaded notification amount with 10
      this.loadedNotificationAmount += 5

      // check if previous loaded class data more than notification total data
      // set it to total data
      if (this.loadedNotificationAmount > this.totalNotification) {
        this.loadedNotificationAmount = this.totalNotification
      }

      // execute fetch notification data with settled amount loaded notification data
      await this.$store.dispatch(
        'notificationStore/fetchNotificationData',
        this.loadedNotificationAmount
      )
      // this.readNotification = this.
      // this.filterNotification()
    },

    // filterNotification() {
    //   this.unReadNotification = []
    //   this.readNotification = []
    //   if (this.getNotificationData.length) {
    //     const dateNow = moment().format('YYYY-MM-DD 00:00:00')
    //     this.getNotificationData.forEach((el: any) => {
    //       const createdAt = moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')
    //       if (createdAt > dateNow) {
    //         this.unReadNotification.push(el)
    //       } else {
    //         this.readNotification.push(el)
    //       }
    //     })
    //   }
    // },

    dateLong(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
    },

    dateNotif(date: string) {
      return moment(date)
        .locale('id')
        .format('D MMM')
    },

    timeSince(date: any) {
      const dateSince: any = new Date(date)
      const dateNow: any = new Date()
      const seconds = Math.floor((dateNow - dateSince) / 1000)

      let interval = seconds / 31536000

      if (interval > 1) {
        return Math.floor(interval) + ' tahun lalu'
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return Math.floor(interval) + ' bulan lalu'
      }
      interval = seconds / 86400
      if (interval > 1) {
        return Math.floor(interval) + ' hari lalu'
      }
      interval = seconds / 3600
      if (interval > 1) {
        return Math.floor(interval) + ' jam lalu'
      }
      interval = seconds / 60
      if (interval > 1) {
        return Math.floor(interval) + ' menit lalu'
      }
      return Math.floor(seconds) + ' detik lalu'
    },
  },
})
</script>

<style scoped></style>
